import firebaseClient from 'firebase/app';
import 'firebase/functions';
import 'firebase/analytics';
import 'firebase/performance';

let analytics: firebaseClient.analytics.Analytics;
let performance: firebaseClient.performance.Performance;

if (typeof window !== 'undefined' && !firebaseClient.apps.length) {
  firebaseClient.initializeApp({
    apiKey: "AIzaSyDNzphbRhu8PtrMOs_BqPPzWLCf7kNNvrU",
    authDomain: "dolores-hair-designers.firebaseapp.com",
    projectId: "dolores-hair-designers",
    storageBucket: "dolores-hair-designers.appspot.com",
    messagingSenderId: "30038035664",
    appId: "1:30038035664:web:99c6c4c63bc4d7ceb862d5",
    measurementId: "G-BG55MCFBT6"
  });
  (window as any).firebase = firebaseClient;
  analytics = firebaseClient.analytics();
  performance = firebaseClient.performance();
};

export { firebaseClient, analytics, performance };