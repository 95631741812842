import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { TextField, Paper, Typography, Grid, Button, CircularProgress } from "@material-ui/core";
import { SnackbarCompProps } from '../types/SnackbarProps';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
import { load } from "recaptcha-v3";
import { PageProps } from 'gatsby';
import { WindowLocation } from '../types/PageProps';
import { firebaseClient } from '../firebaseClient';
import PhoneTextField from "../components/PhoneTextField";
import Layout from '../components/layout';
import GoogleMapReact from 'google-map-react';
import theme from '../theme';
import axios from "axios";
import Snackbar from "../components/Snackbar";

const secondary = theme.palette.secondary.main;

interface ReCaptchaInstance {
  execute(action?: string): Promise<string>;
  getSiteKey(): string;
  hideBadge(): void;
  showBadge(): void;
};

const useStyles = makeStyles({
  paper: {
    marginTop: 100,
    marginBottom: 100,
    height: 550,
    width: 700,
    backgroundColor: 'transparent'
  },
  title: {
    fontFamily: 'Raleway',
    color: secondary,
    padding: 25,
    fontWeight: 'bold'
  }
})

const ContactUs: FunctionComponent<PageProps> = ({ location }) => {
  const [locationState, setLocationState] = useState<WindowLocation<unknown> | undefined>(location);

  const classes = useStyles();
  const [snackbarState, setSnackbarState] = useState<SnackbarCompProps>({
    message: "",
    open: false,
    severity: "success"
  });
  const [loaderState, setLoaderState] = useState(false);
  const [recaptcha, setRecaptcha] = useState<ReCaptchaInstance | null>(null);
  const initialFormState = {
    name: "",
    email: "",
    phone: "",
    message: ""
  };
  const [formData, setFormData] = useState(initialFormState);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState({ ...snackbarState, open: false });
  };

  useEffect(() => {
    if (location) {
      setLocationState(locationState);
    };
    load('6LdKHUkcAAAAAEyXC-3hQ5m8px5FJRRjXL_HTVhH').then(ReCaptchaInstance => {
      setRecaptcha(ReCaptchaInstance);
      ReCaptchaInstance.showBadge();
    }).catch(e => console.log(e));
    return () => recaptcha?.hideBadge();
  }, [recaptcha]);

  //Google map settings
  const options = {
    zoom: 17,
    center: {lat: 40.066579, lng: -83.096549 }
  };

  //Google map markers //Can render custom marker components if you would like
  const renderMarkers = (map: any, maps: any) => {
    let marker = new maps.Marker({
      position: options.center,
      map,
      title: 'We are here'
    });
    return marker
  };
  
  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const field = e.currentTarget.name;
    setFormData({ ...formData, [field]: e.currentTarget.value });
  };

  const onSubmit = async () => {
    try {
      for (const [_key, value] of Object.entries(formData)) {
        if (value.length === 0) {
          return;
        };
      };
      setLoaderState(true);
      if (recaptcha) {
        const token = await recaptcha.execute("submit_form");
        const { data } = await firebaseClient.functions().httpsCallable("verifyRecaptcha")({ token }) as { data: boolean };
        if (data) {
          let bodyFormData = new FormData();
          bodyFormData.append("name", formData.name);
          bodyFormData.append("email", formData.email);
          bodyFormData.append("phone", formData.phone);
          bodyFormData.append("message", formData.message);
          await axios({
            method: "POST",
            url: "https://getform.io/f/b1addb24-8093-4b26-85a9-a738d6668a66",
            data: bodyFormData
          });
          setFormData(initialFormState);
          setSnackbarState({ open: true, severity: "success", message: "Successfully sent message!" });
          setLoaderState(false);
          return;
        };
        setSnackbarState({ open: true, severity: "error", message: "Problem sending message. Please try again" });
        setLoaderState(false);
      }
    } catch (e) {
      console.log(e);
      setSnackbarState({ open: true, severity: "error", message: "Problem sending message. Please try again" });
      setLoaderState(false);
    };
  };

  const ButtonComp = useMemo(() => {
    let type: "submit" | undefined;
    for (const [_key, value] of Object.entries(formData)) {
      if (value.length === 0) {
        type = "submit";
      };
    };
    return (
      <Button
        type={type}
        onClick={onSubmit}
        style={{marginLeft: 25, fontFamily: 'Raleway'}}
        variant="outlined"
      >
        SEND
      </Button>
    );
  }, [formData]);

  return (
    <Layout location={locationState}>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dolores Hair Designers</title>
          <link rel="canonical" href="http://doloreshairdesigners.com/contact" />
        </Helmet>
      </div>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={24}>
            <Typography className={classes.title} variant="h2">
              Contact Us!
            </Typography>
            <form>
              <Grid container direction="column" spacing={5}>
                <Grid item xs={12} >
                  <TextField
                    label="Name"
                    value={formData.name}
                    onChange={onChange}
                    required
                    style={{width: '75%', marginLeft: 25, fontFamily: 'Raleway'}}
                    type="text"
                    name="name"
                    InputLabelProps={{style: {fontFamily: 'Raleway'}}}
                  />
                </Grid>
                <Grid item xs={12} >
                  <TextField
                    value={formData.email}
                    onChange={onChange}
                    label="Email Address"
                    required
                    name="email"
                    type="email"
                    style={{marginLeft: 25, fontFamily: 'Raleway'}}
                    InputLabelProps={{style: {fontFamily: 'Raleway'}}}
                  />
                </Grid>
                <Grid item xs={12} >
                  <PhoneTextField
                    value={formData.phone}
                    onChange={onChange}
                    label="Phone Number"
                    name="phone"
                    type="tel"
                    style={{marginLeft: 25, fontFamily: 'Raleway'}}
                    InputLabelProps={{style: {fontFamily: 'Raleway'}}}
                    required
                  />
                </Grid>
                <Grid item xs={12} >
                  <TextField
                    value={formData.message}
                    onChange={onChange}
                    label="Message"
                    required
                    name="message"
                    type="text"
                    multiline
                    style={{marginLeft: 25, width: '75%', fontFamily: 'Raleway'}}
                    InputLabelProps={{style: {fontFamily: 'Raleway'}}}
                  />
                </Grid>
                <Grid item xs={12}>
                  {loaderState ?
                    <div style={{ marginLeft: "5%" }}>
                      <CircularProgress size={30} color="secondary" variant="indeterminate" />
                    </div>
                  :
                    ButtonComp
                  }
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: 500, width: 700 }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyDkRrjKOXWabvFAj6vfGXAX-wqS_TBM4kw' }}
              defaultCenter={options.center}
              defaultZoom={options.zoom}
              onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
            >
            </GoogleMapReact>
          </div>
        </Grid>
      </Grid>
      <Snackbar open={snackbarState.open} message={snackbarState.message} severity={snackbarState.severity} handleClose={handleClose} />
    </Layout>
  );
};

export default ContactUs
