import React, { FunctionComponent } from 'react';
import { SnackbarCompProps } from '../types/SnackbarProps';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

interface ComponentProps extends SnackbarCompProps {
  handleClose: (event?: React.SyntheticEvent, reason?: string) => void
};

const SnackbarComp: FunctionComponent<ComponentProps> = ({ open, handleClose, message, severity }) => {

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComp;